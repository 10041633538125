/* * {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.ck-content {
  min-height: 5rem !important;
  height: auto !important;
  max-height: 25rem !important;
  font: inherit;
  line-height: 1.5;
  width: 100%;
  padding: 8px 12px;
  overflow: auto;
}
.w-100 {
  width: 100% !important;
}

.ck-editor {
  width: 100% !important;
}

label {
  display: block;
  margin-bottom: 4px;
}

[contenteditable] {
  height: 10rem;
  border: 1px solid;
  resize: both;
}
.ck-content {
  width: 100%;
  min-height: 50vh;
} */


.ck-dropdown__panel-visible{
  max-height: 200px;
  overflow-y: auto;
}